
import Card from "@/models/Card";
import { Vue, Prop, Component } from "vue-property-decorator";

@Component
export default class TheExampleCard extends Vue {
  @Prop({ type: Card, required: true }) card!: Card;

  openExternalLink(url: string): void {
    window.open(url, "_blank", "noopener noreferrer");
  }
}
